import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
// Function to get tokens from local storage
const getAccessToken = () => localStorage.getItem('accessToken');
const getRefreshToken = () => localStorage.getItem('refreshToken');
const userId = localStorage.getItem('userId')
// Function to set the new access token
const setAccessToken = (token) => {
  console.log("settoken",token)
  localStorage.setItem('accessToken', token);
};

// Function to refresh the access token
const refreshAccessToken = async () => {
  const refreshToken = getRefreshToken();

  if (!refreshToken) {
    throw new Error('No refresh token available');
  }

  try {
    const response = await axios.post('https://realestate-backend-bosp.onrender.com/api/v1/users/refresh-token', {
      refreshToken,
    });

    console.log("refressgresponse",response)
    const newAccessToken = response.data.data.accessToken;
    setAccessToken(newAccessToken);
   
    return newAccessToken;
  } catch (error) {
    console.error('Refresh token failed', error);
    throw error;
  }
}


const handleLogout = async () => {
  console.log("clicked hua ki nhi");
 

  try {
    // Send a POST request to the logout endpoint
    const response = await axios.post(`https://realestate-backend-bosp.onrender.com/api/v1/users/logout/${userId}`, {
      headers: {
        'Authorization': `${getAccessToken()}`, // Include the token here
      }
    });

 
    toast.error('logout successful!');

     

    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('username');

     window.location.href='/'
  } catch (error) {
    console.error("error", error);
    // Optionally display an error message to the user
  }
};

const accessToken=localStorage.getItem('accessToken')

// Create an Axios instance with a base URL
const axiosInstance = axios.create({
   baseURL: `https://realestate-backend-bosp.onrender.com/api/v1`, 
  // baseURL: `http://localhost:8000/api/v1`,
  headers: {
    'Content-Type': 'application/json', // Common header for all requests
    'Authorization': `${getAccessToken()}`
   // Example header for authorization
  },
});


// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers['Authorization'] = `${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    
    return response; // Return the response as-is
  },
  async (error) => {
    const originalRequest = error.config;
    console.log("errorHAndler",error.response)

  
    // If the response status is 401 (Unauthorized), try to refresh the token
    if (error.response && error.response.data.message === "jwt expired") {
      // Prevent infinite loops
      try {
        const newAccessToken = await refreshAccessToken();
        console.log("newAccessToken",newAccessToken) // Attempt to refresh token
        originalRequest.headers['Authorization'] = `${newAccessToken}`;
        return axiosInstance(originalRequest); // Retry the original request
      } catch (err) {
        // Handle refresh token failure (e.g., redirect to login)
        console.error('Refresh token failed', err);
        await handleLogout()
        return Promise.reject(err);
      }
    }
    
    if (error.response.status===500 && error.response.data.message === "Refresh token is expired or used") {
      return await handleLogout()
    }
   

    return Promise.reject(error); // Reject other errors
  }
);



export default axiosInstance;