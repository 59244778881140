import React ,{useState}from 'react'
import { RxCross2 } from "react-icons/rx";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import axiosInstance from '../AxiosInstance';
import { FaEyeSlash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
function Signup({ handlesignup,setLogin,setSignup }) {

  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    phone: ''
  });

 const [errors, setErrors] = useState({});

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Validate form data
  const validate = () => {
    const newErrors = {};

    if (!formData.username) newErrors.username = 'Username is required.';
    
    if (!formData.email) {
      newErrors.email = 'Email is required.';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = 'Invalid email format.';
    }
    
    if (!formData.password) {
      newErrors.password = 'Password is required.';
    } else if (formData.password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters long.';
    }
    
    if (!formData.phone) {
      newErrors.phone = 'Phone number is required.';
    } else if (formData.phone.length < 10) {
      newErrors.phone = 'Phone no must be at least 10 characters long.';
    } // Add phone validation if needed

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  //handle change to login

  const handleChangeTologin=()=>{
    setLogin(true)
    setSignup(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form data
    if (!validate()) return;
    setIsSubmitting(true)
    try {
      const response = await axiosInstance.post('/users/register', formData);

      console.log('Sign-up successful:', response.data);
      toast.success('Sign-up successful!');
      // Handle successful sign-up (e.g., navigate to login page or other actions)
      setTimeout(()=>{
        handlesignup()
        setIsSubmitting(false)
      },1000)
    } catch (error) {
      console.error('Sign-up error:', error.response ? error.response.data.message : 'An error occurred');
      toast.error('Sign-up failed. Please try again.');
      setIsSubmitting(false)
    }
  };

  return (
    <div>
      <div class="relative z-20" aria-labelledby="modal-title" role="dialog" aria-modal="true">

        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

        <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0 ">

            <div class="relative transform overflow-hidden w-full  rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">


              <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 ">
                <div className='flex justify-end'><RxCross2 onClick={handlesignup} className='' /></div>
                <div className='text-center my-4 font-bold text-[24px]'>Signup</div>
                <form className='flex flex-col gap-y-4' >
                  <input type='text' placeholder='username*' 
                   className={`border ${errors.username ? 'border-red-500' : 'border-gray-300'} focus:border-violet-700 outline-none rounded w-full px-4 h-14 text-sm`}
                    value={formData.username}
                    name='username'
                    onChange={handleChange}
                  />
                  {errors.username && <span className='text-red-500 text-sm'>{errors.username}</span>}
                  <input type="email" placeholder='email*' 
                    className={`border ${errors.email ? 'border-red-500' : 'border-gray-300'} focus:border-violet-700 outline-none rounded w-full px-4 h-14 text-sm`}
                    name='email'
                    value={formData.email}
                    onChange={handleChange}
                   />
                  {errors.email && <span className='text-red-500 text-sm'>{errors.email}</span>}
                  {/* <input type="text" placeholder='password*' 
                      className={`border ${errors.password ? 'border-red-500' : 'border-gray-300'} focus:border-violet-700 outline-none rounded w-full px-4 h-14 text-sm`}
                      name='password'
                      type='password'
                      value={formData.password}
                      onChange={handleChange}
                   />
                    <button
                            type="button"
                            onClick={togglePasswordVisibility}
                            className="absolute inset-y-0 right-0 flex items-center pr-3"
                          >
                            {showPassword ? (
                              <FaEye  className="h-5 w-5 text-gray-500" />
                            ) : (
                              <FaRegEyeSlash className="h-5 w-5 text-gray-500" />
                            )}
                          </button> */}
                          <div className="relative">
                              <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder='password*'
                                className={`border ${errors.password ? 'border-red-500' : 'border-gray-300'} focus:border-violet-700 outline-none rounded w-full px-4 h-14 text-sm pr-10`}
                                name='password'
                                value={formData.password}
                                onChange={handleChange}
                              />
                              <button
                                type="button"
                                onClick={togglePasswordVisibility}
                                className="absolute inset-y-0 right-0 flex items-center px-3"
                                aria-label={showPassword ? 'Hide password' : 'Show password'}
                              >
                                {showPassword ? (
                                  <FaEye className="h-5 w-5 text-gray-500" />
                                ) : (
                                  <FaEyeSlash className="h-5 w-5 text-gray-500" />
                                )}
                              </button>
                            </div>
                    {errors.password && <span className='text-red-500 text-sm'>{errors.password}</span>}
                  <input type="text" placeholder='phone*' 
                         className={`border ${errors.phone ? 'border-red-500' : 'border-gray-300'} focus:border-violet-700 outline-none rounded w-full px-4 h-14 text-sm`}
                         name='phone'
                         value={formData.phone}
                         onChange={handleChange} 
                         />
                   {errors.phone && <span className='text-red-500 text-sm'>{errors.phone}</span>}     
                </form>
                <div className='text-end my-4 cursor-pointer text-[14px]'>
                  

                </div>

                <div>
                  <button onClick={handleSubmit}  disabled={isSubmitting} className={`${isSubmitting===true?`bg-green-700`:`bg-green-500`} w-full py-3 rounded-full text-[16px] text-white font-semibold`}>Signup</button>
                </div>
                <div className='text-center my-4'>
                  <p>Already have an account ? <span onClick={handleChangeTologin} className='cursor-pointer text-green-600 hover:text-green-800'> Login</span></p>
                </div>
              </div>
              {/* <div class="bg-gray-50  py-3 sm:flex sm:flex-row-reverse sm:px-6 md:flex-row justify-between lg:flex-row justify-between">
      <button type="button" class="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 sm:ml-3 sm:w-auto">LogIn</button>
      <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" onClick={handle}>Cancel</button>
    </div> */}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default Signup