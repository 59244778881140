import React, { useState } from "react";
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import facebook from '../assets/facebook.png'
import twitter from '../assets/twitter.png'
import insta from '../assets/insta.png'
import linkedin from '../assets/linkedin.png'
import star from '../assets/star.png'
import { color } from "framer-motion";

const Footer2 = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubscribe = (e) => {
    e.preventDefault();
    if (email) {
      setMessage("Thank you for subscribing!");
      setEmail("");
    } else {
      setMessage("Please enter a valid email.");
    }
  };

  return (
    <footer className="bg-[#121212] text-white py-10">
      <div className="container mx-auto px-4 lg:px-0">
        {/* Footer top */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 mb-8">
          {/* Company Info */}
          <div>
            <h3 className="text-2xl font-semibold mb-4">Nextassets</h3>
            <p className="text-gray-400">
              We offer top-tier property services, tailored to meet client needs
              and deliver complete satisfaction. Reach out to learn how we can
              assist you.
            </p>
          </div>

          {/* Links */}
          <div>
            <h3 className="text-xl font-semibold mb-4">Contact us</h3>
            <ul className="space-y-2">
              <li>
                <a
                  href="#"
                  className="text-gray-400 hover:text-green-400 transition-colors"
                >
                  SCO-203 2nd Floor Motia Guildford Square Airport Road
                  Zirakpur, Bir Chatt, Punjab - 140603
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="text-gray-400 hover:text-green-400 transition-colors"
                >
                  +91 9875982215
                </a>
              </li>
              {/* <li><a href="#" className="text-gray-400 hover:text-green-400 transition-colors">+91 </a></li> */}
            </ul>
          </div>

          <div>
            <h3 className="text-xl font-semibold mb-4">Categories</h3>
            <ul className="space-y-2">
              <li>
                <a
                  href="#"
                  className="text-gray-400 hover:text-green-400 transition-colors"
                >
                  Recent property
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="text-gray-400 hover:text-green-400 transition-colors"
                >
                  To Sell
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="text-gray-400 hover:text-green-400 transition-colors"
                >
                  To Buy
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="text-gray-400 hover:text-green-400 transition-colors"
                >
                  To Rent
                </a>
              </li>
            </ul>
          </div>

          {/* Social Media Links */}
          <div>
            <h3 className="text-xl font-semibold mb-4">Follow Us</h3>
            <div className="flex space-x-4">
              <a href="#" className="text-gray-400 hover:text-green-400 transition-colors">
               <img src={facebook} size={20} alt="facebook"></img>
              </a>
              <a href="#" className="text-gray-400 hover:text-green-400 transition-colors">
              <img src={twitter} size={20} alt="twitter"></img>
              </a>
              <a href="#" className="text-gray-400 hover:text-green-400 transition-colors">
              <img src={insta} size={20} alt="insta"></img>
              </a>
              <a href="#" className="text-gray-400 hover:text-green-400 transition-colors">
              <img src={linkedin} size={20} alt="linkedin"></img>
              </a>
            </div>
          </div>
        </div>

        {/* Footer bottom */}
        <div className="border-t pt-6">
          <p className="text-center text-gray-400">&copy; 2024 Nextassets <span className=" text-white font-medium rounded-md"> powered by </span>✨nextworktechnologies.com</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer2;
