import axios from 'axios';
import React, { useState,useContext } from 'react'
import { RxCross2 } from "react-icons/rx";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from '../AxiosInstance';
import { HouseContext } from './HouseContext';
import {ImSpinner2} from "react-icons/im"
function Login({handlelogin,setLogin,setSignup,setForgot}) {

  const {setLoading,loading}=useContext(HouseContext)

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [username,setUsername]=useState('');
  const [password,setPassword]=useState('')
  const [data,setData]=useState('')
  const handletoSignUp=()=>{
    setLogin(false)
    setSignup(true)
  }
   

  const handleforgot=()=>{
    setLogin(false)
    setForgot(true)
  }
const handleLoginApi=async()=>{
  console.log("clicked")
  setLoading(true)
  setIsSubmitting(true)
  try {

    if (!username || !password) {
      toast.error('Username and password are required.');
      return;
    }

    if (password.length < 6) {
      toast.error('Password must be at least 6 characters long.');
      return;
    }
    
    const data={
      username:username,
      email:username,
      password:password
    }
    const response=await axiosInstance.post(`/users/login`,data)

    console.log("data",response.data.data.loggedInUser)
    localStorage.setItem('accessToken', response.data.data.user);
    localStorage.setItem('refreshToken', response.data.data.refreshToken);
    localStorage.setItem('userId', response.data.data.loggedInUser._id);
    localStorage.setItem('username', response.data.data.loggedInUser.username);
    
 
    toast.success('Login successful!');
    setTimeout(()=>{
      setLoading(false)
      handlelogin()
      setIsSubmitting(false)
    },1000)
    
  } catch (error) {
    setIsSubmitting(false)
    console.log(error.response ? error.response.data.message : 'An error occurred');
  }
}
  return (
    <div>
        <div class="relative z-20" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0 ">
   
      <div class="relative transform overflow-hidden w-full  rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
     
       
        <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 ">
                      <div className='flex justify-end'><RxCross2 onClick={handlelogin} className=''/></div>
                     <div className='text-center my-4 font-bold text-[24px]'>Login</div>
                     <form  className='flex flex-col gap-y-4'>
                        <input type='text' placeholder='username/email*' value={username} onChange={(e)=>setUsername(e.target.value)} className='border border-gray-300 focus:border-violet-700 outline-none rounded w-full px-4 h-14 text-sm'/>
                       
                        <input type="password" placeholder='password*' value={password} onChange={(e)=>setPassword(e.target.value)} className='border border-gray-300 focus:border-violet-700 outline-none rounded w-full px-4 h-14 text-sm'/>
                       
                      </form>
                      <div className='text-end my-2 cursor-pointer text-[14px]'>
                        <p onClick={handleforgot}>Forgot Password?</p>
                       
                      </div>

                      <div>
                        <button  onClick={handleLoginApi}  disabled={isSubmitting}  className={`${isSubmitting===true ?`bg-green-700`:`bg-green-500`} flex justify-center items-center   w-full py-3 rounded-full text-[16px] text-white font-semibold`}>Login <span className='ml-2'>{loading?<ImSpinner2 className='animate-spin'/>:""}</span></button>
                      </div>
                      <div className='text-center my-4'>
                         <p>Don't have an account? <span onClick={handletoSignUp} className='text-green-600 hover:text-green-800 cursor-pointer'>Register</span></p> 
                      </div>
        </div>
        {/* <div class="bg-gray-50  py-3 sm:flex sm:flex-row-reverse sm:px-6 md:flex-row justify-between lg:flex-row justify-between">
          <button type="button" class="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 sm:ml-3 sm:w-auto">LogIn</button>
          <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" onClick={handle}>Cancel</button>
        </div> */}
      </div>
    </div>
  </div>
</div>
<ToastContainer />
    </div>
  )
}

export default Login