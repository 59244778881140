import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer2 from "./components/Footer2";
import ScrollToTop from "./components/ScrollToTop";
import { Helmet, HelmetProvider } from "react-helmet-async";
// Lazy load pages
const Home = React.lazy(() => import("./pages/Home"));
const PropertyDetails = React.lazy(() => import("./pages/PropertyDetails"));
const AddPropertyPage = React.lazy(() => import("./pages/AddPropertyPage"));
const UserProfile = React.lazy(() => import("./pages/UserProfile"));
const ConfirmPassword = React.lazy(() =>
  import("./components/ConfirmPassword")
);
const Favorite = React.lazy(() => import("./pages/Favorite"));
const App = () => {
  return (
    <HelmetProvider>
      <div className="mx-auto bg-white">
        <Header />
        <ScrollToTop />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/property/:id" element={<PropertyDetails />} />
            <Route path="/addproperty" element={<AddPropertyPage />} />
            <Route path="/profilepage" element={<UserProfile />} />
            <Route path="/confirmpassword" element={<ConfirmPassword />} />
            <Route path="/favorite" element={<Favorite />} />
          </Routes>
        </Suspense>
        <Footer2 />
      </div>
    </HelmetProvider>
  );
};

export default App;
