import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/img/logonew.png";
import Login from "./Login";
import Signup from "./Signup";
import Forgot from "./Forgot";
import avatarLocal from "../assets/img//avatar2.jpg";
import { FaPlus } from "react-icons/fa6";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "../AxiosInstance";
import { RiHeart3Line } from "react-icons/ri";
import { FiAlignJustify } from "react-icons/fi";
const Header = () => {
  const navigate = useNavigate();
  const [login, setLogin] = useState(false);
  const [signup, setSignup] = useState(false);
  const [forgot, setForgot] = useState(false);
  const [avatar, setAvatar] = useState("");
  const [toggle, setToggle] = useState(false);
  const [addpropertybutton, setaddpropertybutton] = useState(true);
  const [favorite, setFavorite] = useState(true);
  const username = localStorage.getItem("username");
  const userId = localStorage.getItem("userId");
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const togglehandler = () => setToggle(!toggle);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleResize = () => {
    // Set isToggled to false if window width is 768 pixels or more
    if (window.innerWidth >= 1023) {
      setToggle(false);
    }
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Add event listener for clicks outside the dropdown
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchAvatar = async () => {
      if (userId) {
        try {
          const response = await axiosInstance.get(`/users/${userId}/avatar`);
          setAvatar(response.data.data.avatar);
        } catch (error) {
          console.error("Error fetching avatar:", error);
        }
      }
    };
    fetchAvatar();
  }, []);

  const handleLogout = async () => {
    console.log("clicked hua ki nhi");
    toggleDropdown();

    try {
      // Send a POST request to the logout endpoint
      const response = await axiosInstance.post(`/users/logout/${userId}`, {});

      toast.error("logout successful!");

      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("userId");
      localStorage.removeItem("username");
      setToggle(false);
      navigate("/");
    } catch (error) {
      console.error("error", error);
      // Optionally display an error message to the user
    }
  };

  const handleLogin = () => {
    console.log("clicked");
    if (login === true) {
      setLogin(false);
      window.location.href = "/";
    } else {
      setLogin(true);
    }
  };

  const handleSignup = () => {
    if (signup === true) {
      setSignup(false);
    } else {
      setSignup(true);
    }
  };

  const handleForgot = () => {
    if (forgot === true) {
      setForgot(false);
    } else {
      setForgot(true);
    }
  };

  const handleLogo = () => {
    navigate("/");
  };

  return (
    <header className="py-6 mb-12 border-b z-50">
      <div className="container mx-auto max-w-[1400px] flex justify-between items-center">
        <Link
          to="/"
          onClick={() => {
            setaddpropertybutton(true);
          }}
        >
          <img src={Logo} className="h-24 w-auto max-w-full" alt="homeland" />
        </Link>

        <div className="hidden lg:flex items-center gap-6">
          {!(username === undefined || username === null) ? (
            <div className="text-[14px] transition border-2 border-green-500 text-white bg-green-500 hover:text-white hover:bg-green-700 py-2 px-3 rounded-md">
              <Link
                to="/favorite"
                className="flex justify-center items-center gap-1"
              >
                <span>
                  <RiHeart3Line className="text-[18px]" />
                </span>
                <span>Favorite</span>
              </Link>
            </div>
          ) : null}

          {!(username === undefined || username === null) ? (
            <div className="flex justify-between items-center gap-5">
              {addpropertybutton ? (
                <div className="text-[14px] transition border-2 border-green-600 text-green-600 hover:text-white hover:bg-green-700 py-2 px-3 rounded-md">
                  <Link
                    to="/addproperty"
                    onClick={() => {
                      setaddpropertybutton(false);
                    }}
                    className="flex justify-center items-center gap-1"
                  >
                    <span>
                      <FaPlus />
                    </span>
                    <span>Add Property</span>
                  </Link>
                </div>
              ) : null}

              <div className="relative flex justify-between items-center">
                <img
                  onClick={toggleDropdown}
                  className="w-12 h-12 rounded-full mx-2 cursor-pointer"
                  src={avatar ? avatar : avatarLocal}
                  alt="Rounded avatar"
                />

                {isOpen && (
                  <div className="absolute right-0 top-full mt-2  w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-20">
                    <ul className="py-1">
                      <li>
                        <Link
                          to="/profilepage"
                          onClick={() => {
                            setaddpropertybutton(true);
                            toggleDropdown();
                          }}
                        >
                          <a
                            href="#"
                            className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                          >
                            Profile
                          </a>
                        </Link>
                      </li>
                      <li>
                        <a
                          href="#"
                          className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                          onClick={handleLogout}
                        >
                          Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="">
              <Link
                to=""
                className="hover:text-green-600 text-[14px] transition"
                onClick={handleLogin}
              >
                Log in
              </Link>
              <Link
                to=""
                className="bg-green-600 hover:bg-green-700 px-4 py-3 rounded-lg text-white text-[14px] mx-3"
                onClick={handleSignup}
              >
                Sign up
              </Link>
            </div>
          )}
        </div>

        <div className="lg:hidden">
          <FiAlignJustify
            data-dropdown-toggle="dropdown"
            id="dropdownDefaultButton"
            className="text-[28px]   focus:ring-4 focus:outline-none "
            onClick={togglehandler}
          />
        </div>
      </div>
      {login === true ? (
        <Login
          handlelogin={handleLogin}
          setLogin={setLogin}
          setSignup={setSignup}
          setForgot={setForgot}
        />
      ) : null}
      {signup === true ? (
        <Signup
          handlesignup={handleSignup}
          setLogin={setLogin}
          setSignup={setSignup}
        />
      ) : null}
      {forgot === true ? (
        <Forgot
          handlelogin={handleLogin}
          setLogin={setLogin}
          setSignup={setSignup}
        />
      ) : null}
      {toggle ? (
        <div
          id="dropdown"
          className=" bg-white min-w-full  divide-gray-100 rounded-lg "
        >
          <ul
            className="min-w-full py-2 text-sm text-center text-green-600 dark:text-gray-200 w-full"
            aria-labelledby="dropdownDefaultButton"
          >
            {!(username === undefined || username === null) ? (
              <ul
                className="min-w-full py-2 text-sm text-center text-green-600 dark:text-gray-200 w-full"
                aria-labelledby="dropdownDefaultButton"
              >
                <li className="hover:bg-gray-100">
                  <Link
                    to="/profilepage"
                    onClick={() => {
                      setaddpropertybutton(true);
                      toggleDropdown();
                      setToggle(false);
                    }}
                  >
                    <a href="#" className="block px-4 py-2 ">
                      Profile
                    </a>
                  </Link>
                </li>
                <li className="hover:bg-gray-100">
                  <Link
                    to="/addproperty"
                    onClick={() => {
                      setaddpropertybutton(false);
                      setToggle(false);
                    }}
                  >
                    <a href="#" className="block px-4 py-2 ">
                      Add Property
                    </a>
                  </Link>
                </li>
                <li className="hover:bg-gray-100">
                  <Link to="/favorite">
                    <a href="#" className="block px-4 py-2 ">
                      Favorite
                    </a>
                  </Link>
                </li>
                <li className="hover:bg-gray-100">
                  <a
                    href="#"
                    className="block px-4 py-2 cursor-pointer "
                    onClick={handleLogout}
                  >
                    Logout
                  </a>
                </li>
              </ul>
            ) : (
              <ul
                className="min-w-full py-2 text-sm text-center text-green-600 dark:text-gray-200 w-full"
                aria-labelledby="dropdownDefaultButton"
              >
                <li className="hover:bg-gray-100 my-2">
                  <Link
                    to=""
                    className=" px-7 py-3 rounded-lg text-green-600 hover:text-green-800 border-2 border-green-600 hover:border-green-800 text-[14px] "
                    onClick={handleLogin}
                  >
                    Log in
                  </Link>
                </li>
                <li className="hover:bg-gray-100 mt-8">
                  <Link
                    to=""
                    className="bg-green-600 hover:bg-green-700 px-6 py-3 rounded-lg text-white text-[14px] "
                    onClick={handleSignup}
                  >
                    Sign up
                  </Link>
                </li>
              </ul>
            )}
          </ul>
        </div>
      ) : null}
      <ToastContainer />
    </header>
  );
};

export default Header;
