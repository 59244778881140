import React,{useState} from 'react'
import { RxCross2 } from "react-icons/rx";
import { ToastContainer, toast } from 'react-toastify';
import Axiosinstance from '../AxiosInstance.js'
function Forgot({handlelogin,setLogin,setForgot}) {

    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleLoginBack=()=>{
        setLogin(true)
        setForgot(false)
    }

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        setError('');

        try {
            const response = await Axiosinstance.post('/users/forgot-password', { email:email });
            setMessage(response.data.message || 'Check your email for reset instructions.');
        } catch (err) {
            setError(err.response?.data?.error || 'An error occurred. Please try again.');
        }
    };
  return (
    <div>
        <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">

             <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0 ">

                    <div class="relative transform overflow-hidden w-full  rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">


                        <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 ">
                                        <div className='flex justify-end'><RxCross2 onClick={()=>setForgot(false)} className=''/></div>
                                        <div className='text-center my-4 font-bold text-[24px]'>Forgot Password</div>
                                        <p className='text-sm my-2'>Enter your email we'll send you a link to reset your password.</p>
                                        <form onSubmit={handleSubmit} className='flex flex-col gap-y-4'>
                                        <input type='text' placeholder='Enter your email *' 
                                            name="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            className='border border-gray-300 focus:border-violet-700 outline-none rounded w-full px-4 h-14 text-sm'/>
                                            {message}
                                        
                                            <div className='my-4'>
                                        <button  disabled={isSubmitting} type="submit" className={`${isSubmitting===true ?`bg-green-700`:`bg-green-500`} w-full py-3 rounded-full text-[16px] text-white font-semibold`}>Submit</button>
                                        </div>
                                        </form>
                                        

                                        
                                        <div className='text-center text-green-500 my-2 cursor-pointer text-[14px]'>
                                        <p onClick={handleLoginBack}>Back to Login</p>
                                        
                                        </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    <ToastContainer />
    </div>
  )
}

export default Forgot