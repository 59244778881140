import React, { useState, useEffect, createContext } from "react";
import axios from "axios";
import { BASE_URL } from "../constents.js";
import { housesData } from "../data";
import axiosInstance from "../AxiosInstance.js";

export const HouseContext = createContext();

const HouseContextProvider = ({ children }) => {
  //search values
  const [searchValue, setSearchValue] = useState("");

  // product filter state
  const [product, setProduct] = useState([]);

  // main api data state
  const [houses, setHouses] = useState([]);

  //country filter states
  const [country, setCountry] = useState("Location (any)");
  const [countries, setCountries] = useState([]);

  //Propert type filter states ("sell","rent")
  const [propertyType, setPropertyType] = useState("Type (any)");
  const [propertiesType, setPropertiesType] = useState([]);

  //Property category filter states ("house","villa","plot",)
  const [property, setProperty] = useState("Category (any)");
  const [properties, setProperties] = useState([]);

  //Furnished category filter states ("house","villa","plot",)
  const [furnised, setfurnised] = useState("Furnished (any)");
  const [furnisedvalue, setfurnisedvalue] = useState([]);

  //rice filter states ("house","villa","plot",)
  const [price, setPrice] = useState("Price (any)");
  const [loading, setLoading] = useState(false);

  const productAPI = async () => {
    try {
      const response = await axiosInstance.get(`/property/all-property`);
      setHouses(response?.data?.data);
      setProduct(response?.data?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    productAPI();
  }, []);

  useEffect(() => {
    // Define the async function within the effect
    const fetchCountries = async () => {
      const allCountries = product.map((house) => house.location);
      const uniqueCountries = ["Location (any)", ...new Set(allCountries)];
      setCountries(uniqueCountries);
    };

    if (houses.length) {
      fetchCountries(); // Call the async function
    }
  }, [houses]); // Depend on houses

  useEffect(() => {
    // Define the async function within the effect
    const fetchProperties = async () => {
      const allProperties = product.map((house) => house.category);
      const uniqueProperties = [
        "Property Category (any)",
        ...new Set(allProperties),
      ];
      setProperties(uniqueProperties);
      console.log("properties", properties);
    };

    if (houses.length) {
      fetchProperties(); // Call the async function
    }
  }, [houses]);

  useEffect(() => {
    // Define the async function within the effect
    const fetchFurnishedType = async () => {
      const allFurnishedtype = product.map((house) => house.furnished);

      const uniqueFurnishedtype = [
        "Furnished type (any)",
        ...new Set(allFurnishedtype),
      ];
      // console.log("sds",uniquePropertiestype)
      setfurnisedvalue(uniqueFurnishedtype);
    };

    if (houses.length) {
      fetchFurnishedType(); // Call the async function
    }
  }, [houses]);
  const fetchPropertiesType = async () => {
    const allPropertiestype = product.map((house) => house.type);

    const uniquePropertiestype = [
      "Property type (any)",
      ...new Set(allPropertiestype),
    ];

    setPropertiesType(uniquePropertiestype);
  };

  useEffect(() => {
    if (houses.length) {
      fetchPropertiesType(); // Call the async function
    }
  }, [houses]);

  //  const handleClick=()=>{
  //      setLoading(true)

  //     const isDefault=(str)=>{
  //       return str.split(" ").includes('(any)')
  //     }

  //     const maxPrice=parseInt(price.split("-")[1]);
  //     const minPrice=parseInt(price.split("-")[0]);

  //     const newHouses=product.filter((house)=>{
  //       const housePrice=parseInt(house.price);

  //       if(house.location === country && house.category === property && house.type===propertyType && house.furnished===furnised && house.price>=minPrice && house.price<=maxPrice){
  //         return house
  //       }

  //       if(isDefault(country) && isDefault(property) && isDefault(price) && isDefault(propertyType) && isDefault(furnised) ){
  //         return house
  //       }

  //       if(!isDefault(country) && isDefault(property) && isDefault(price) && isDefault(propertyType) && isDefault(furnised)){
  //         return house.location === country
  //       }

  //       if(isDefault(country) && !isDefault(property) && isDefault(price) && isDefault(propertyType) && isDefault(furnised)){
  //         return house.category === property
  //       }

  //       if(isDefault(country) && isDefault(property) && !isDefault(price)&& isDefault(propertyType) && isDefault(furnised) ){
  //         return house.price>=minPrice && house.price<=maxPrice
  //       }

  //       if(isDefault(country) && isDefault(property) && isDefault(price)&& !isDefault(propertyType) && isDefault(furnised) ){
  //         return house.type===propertyType
  //       }

  //       if(isDefault(country) && isDefault(property) && isDefault(price)&& isDefault(propertyType) && !isDefault(furnised) ){
  //         return house.furnished===furnised
  //       }

  //       if(!isDefault(country) && !isDefault(property) && !isDefault(propertyType) && !isDefault(furnised) && isDefault(price) ){
  //         return house.location === country &&  house.category === property && house.type===propertyType && house.furnished===furnised
  //       }

  //       if(!isDefault(country) && isDefault(property) && !isDefault(price) && !isDefault(propertyType) && !isDefault(furnised) ){
  //         return house.location === country &&  house.price>=minPrice && house.price<=maxPrice && house.type===propertyType && house.furnished===furnised
  //       }

  //       if(isDefault(country) && !isDefault(property) && !isDefault(price) && !isDefault(propertyType) && !isDefault(furnised) ){
  //         return house.category === property &&  house.price>=minPrice && house.price<=maxPrice && house.type===propertyType && house.furnished===furnised
  //       }

  //       if( isDefault(propertyType) &&!isDefault(country) && !isDefault(property) && !isDefault(price)&& !isDefault(furnised) ){
  //         return house.category === property &&  house.price>=minPrice && house.price<=maxPrice && house.location === country && house.furnished===furnised
  //       }

  //       if( !isDefault(furnised) &&!isDefault(propertyType) &&!isDefault(country) && !isDefault(property) && !isDefault(price) ){
  //         return house.category === property &&  house.price>=minPrice && house.price<=maxPrice && house.location === country && house.type===propertyType
  //       }

  //     })

  //     setTimeout(()=>{
  //       return newHouses<1 ? setHouses([]) : setHouses(newHouses) ,setLoading(false);
  //     },1000)

  //  }

  const handleClick = () => {
    setLoading(true);

    const isDefault = (str) => str.split(" ").includes("(any)");

    const maxPrice = parseInt(price.split("-")[1]);
    const minPrice = parseInt(price.split("-")[0]);

    const searchTerm = searchValue.toLowerCase();
    const newHouses = product.filter((house) => {
      // Apply conditions based on the values of country, property, price, propertyType, and furnished
      const isLocationMatch = isDefault(country) || house.location === country;
      const isCategoryMatch =
        isDefault(property) || house.category === property;
      const isPriceMatch =
        isDefault(price) ||
        (house.price >= minPrice && house.price <= maxPrice);
      const isTypeMatch =
        isDefault(propertyType) || house.type === propertyType;
      const isFurnishedMatch =
        isDefault(furnised) || house.furnished === furnised;

      const isSearchMatch =
        searchTerm === "" || house.location.toLowerCase().includes(searchTerm);
      console.log("kya aaya", isSearchMatch);

      return (
        isLocationMatch &&
        isCategoryMatch &&
        isPriceMatch &&
        isTypeMatch &&
        isFurnishedMatch &&
        isSearchMatch
      );
    });

    console.log("new house", newHouses);

    setTimeout(() => {
      setHouses(newHouses.length < 1 ? [] : newHouses);
      setLoading(false);
    }, 1000);
  };
  return (
    <HouseContext.Provider
      value={{
        country,
        setCountry,
        countries,
        property,
        setProperty,
        properties,
        price,
        setPrice,
        houses,
        loading,
        handleClick,
        propertyType,
        setPropertyType,
        propertiesType,
        furnised,
        setfurnised,
        furnisedvalue,
        loading,
        searchValue,
        setSearchValue,
        setLoading
      }}
    >
      {children}
    </HouseContext.Provider>
  );
};

export default HouseContextProvider;
 